<template>
  <div class="transfer-warranty-stock">
    <KTCodePreview v-bind:title="'Thông tin phiếu chuyển kho bảo hành'">
      <template v-slot:preview>
        <b-row class="pl-0">
          <b-col cols="6">
            <b-row>
              <b-form-group class="col-6">
                <label>Ngày chuyển kho:</label>
                <b-form-input
                  tabindex="1"
                  v-model="transferDate"
                  type="text"
                  size="sm"
                  :disabled="true"
                >
                </b-form-input>
              </b-form-group>
            </b-row>

            <b-row>
              <b-form-group class="col-6 required-control">
                <label>Kho nguồn:</label>
                <Autosuggest
                  :model="searchSourceStore"
                  :suggestions="filteredOptions"
                  @change="onInputChangeSrcStore"
                  @select="onSelectedSrcStore"
                  aria-describedby="input-src-store"
                  size="sm"
                  :limit="10"
                  placeholder="kho nguồn"
                  :disabled="disableSrc"
                >
                  <template #custom="{suggestion}">
                    <div>
                      <span>{{ suggestion.item.suggestionName }}</span>
                    </div>
                  </template>
                </Autosuggest>
              </b-form-group>

              <b-form-group class="col-6 required-control">
                <label>Kho đích:</label>
                <Autosuggest
                  :model="searchDesStore"
                  :suggestions="filteredOptions"
                  @change="onInputChangeDesStore"
                  @select="onSelectedDesStore"
                  aria-describedby="input-des-store"
                  size="sm"
                  :limit="10"
                  placeholder="kho đích"
                >
                  <template #custom="{suggestion}">
                    <div>
                      <span>{{ suggestion.item.suggestionName }}</span>
                    </div>
                  </template>
                </Autosuggest>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group class="col-12">
                <template>
                  <span>Nội dung:</span>
                </template>
                <b-form-textarea
                  size="sm"
                  v-model="notes"
                  :placeholder="'Thêm nội dung...'"
                  :rows="3"
                  :max-rows="3"
                ></b-form-textarea>
              </b-form-group>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mb-5">
          <b-col
            lg="6"
            md="6"
            sm="12"
          >
            <b-row>
              <b-col cols="4">
                <b-form-select
                  class="select-style"
                  v-model="selectTypeSearch"
                  :options="listTypeSearch"
                  size="sm"
                  value-field="id"
                  text-field="name"
                ></b-form-select>
              </b-col>
              <b-col cols="8">
                <Autosuggest
                  :model="searchProduct"
                  :suggestions="filteredOptionsProduct"
                  @change="onInputChangProduct"
                  @select="onSelectedProduct"
                  aria-describedby="input-product"
                  size="sm"
                  :limit="10"
                  placeholder="sản phẩm"
                >
                  <template #custom="{suggestion}">
                    <div>
                      <span>{{ suggestion.item.productName }}</span>
                    </div>
                  </template>
                </Autosuggest>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <span class="font-weight-bolder">Danh sách sản phẩm:</span>
        <table class="
            table table-bordered table-vertical-center table-hover
            col-12
            mt-4
          ">
          <thead>
            <tr>
              <th
                scope="col"
                class="title-center"
              >Tên sản phẩm</th>
              <th
                scope="col"
                class="title-center"
              >IMEI</th>
              <th
                scope="col"
                class="title-center"
              >Số lượng</th>
              <th
                scope="col"
                class="title-center"
              >
                Đơn giá
              </th>
              <th
                scope="col"
                class="title-center"
              >
                Thành tiền
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody
            v-for="item in warrantyItems"
            :key="item.id"
          >
            <TransferWarrantyStockItem
              v-bind:productItem="item"
              v-bind:editable="true"
              v-on:deleteTransferItem="deleteTransferItem"
              v-on:linkToListImei="linkToListImei"
              v-on:onChangeTransferQuantity="onChangeTransferQuantity"
            />
          </tbody>
          <tfoot>
            <tr>
              <td
                colspan="2"
                class="tdTextAlignPrice"
              >
                Tổng
              </td>
              <td class="tdTextAlignPrice">{{ totalQuantity }}</td>
              <td class="tdTextAlignPrice"></td>
              <td class="tdTextAlignPrice">
                {{ convertPrice(totalPrice) }}
              </td>
            </tr>
          </tfoot>
        </table>
      </template>
      <template v-slot:foot>
        <b-button
          v-if="warrantyItems.length > 0"
          style="font-weight: 600 !important; width: 70px; margin-right: 10px"
          variant="primary"
          size="sm"
          class="font-weight-bolder"
          @click="debounceTransfer('save')"
        >
          Lưu
        </b-button>
        <router-link
          to="/warranty-bill"
          tag="button"
        >
          <b-button
            style="font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
          >Trở về</b-button>
        </router-link>
      </template>
    </KTCodePreview>
    <b-modal
      ref="list-imei-modal"
      hide-footer
      :title="modalImeiTitle"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <b-table
          :items="listImeiByProduct"
          :fields="fieldListImei"
          class="table-bordered table-hover col-md-12"
        >
          <template v-slot:table-busy>
            <vcl-table
              :speed="5"
              :animate="true"
              :columns="3"
            ></vcl-table>
          </template>
          <template v-slot:cell(imeiCode)="row">
            <div class="d-flex justify-content-center">
              <span v-text="row.item.imeiCode"></span>
            </div>
          </template>
          <template v-slot:cell(sellingPrice)="row">
            <div>
              <span
                v-text="convertPrice(row.item.sellingPrice)"
                class="text-right"
              ></span>
            </div>
          </template>
          <template v-slot:cell(status)="row">
            <div class="d-flex justify-content-center">
              <span
                v-text="checkStatus(row.item.status)"
                v-bind:class="bindClassNameStatus(row.item.status)"
              ></span>
            </div>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <v-icon
                small
                class="text-danger"
                @click="applyImei(row.item)"
                v-b-tooltip
                title="Áp dụng"
              >mdi-pen</v-icon>
            </div>
          </template>
        </b-table>
        <b-button
          style="margin-left: 10px; font-weight: 600; width: 70px"
          variant="secondary"
          size="sm"
          @click="hideModalListImei"
        >Hủy</b-button>
      </v-form>
    </b-modal>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import localData from '@/utils/saveDataToLocal';
import { convertPrice, makeToastFaile } from '@/utils/common';
import ApiService from '../../../core/services/api.service';
import Swal from 'sweetalert2';
import TransferWarrantyStockItem from '../../components/TransferWarrantyStockItem.vue';
import {
  BILL_ITEM_TYPE,
  PRODUCT_TYPE,
  STOCK_SLIP_TYPE,
  WARRANTY_BILL_STATUS,
  IMEI_STATUS,
} from '../../../utils/enum';
import moment from 'moment';
import { makeToastSuccess, unMaskPrice } from '../../../utils/common';
import { debounce } from 'debounce';
import { TIME_TRIGGER } from '@/utils/constants';
import { v4 as uuidv4 } from 'uuid';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

export default {
  components: {
    Autosuggest,
    KTCodePreview,
    TransferWarrantyStockItem,
  },
  data() {
    return {
      selectingProduct: {},
      applyImeiForItemId: '',
      modalImeiTitle: '',
      listImeiByProduct: [],
      fieldListImei: [
        {
          key: 'imeiCode',
          label: 'IMEI',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
        },
        { key: 'actions', label: '' },
      ],
      limit: 10,
      isSearching: false,
      listTypeSearch: [
        {
          id: 1,
          name: 'Tìm sản phẩm',
        },
      ],
      selectTypeSearch: 1,
      filteredOptionsProduct: [],
      searchProduct: '',
      billNumber: null,
      warrantyItems: [],
      totalPrice: 0,
      totalQuantity: 0,
      billStatus: null,
      notes: '',
      transferDate: null,
      filteredOptions: [],
      listInventories: [],
      options: [],
      searchSourceStore: '',
      sourceStoreId: null,
      searchDesStore: '',
      sourceStoreSelected: null,
      desStoreSelected: null,
      selectedProduct: null,
      disableSrc: false,
      errors: [],
      isNew: true,
    };
  },
  async created() {
    this.transferDate = moment(new Date()).format('DD/MM/yyyy');
    await this.fetchStoreByUser();
    await this.fetchStore();
    if (this.billNumber) {
      this.fetchBillById();
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu chuyển kho', route: '/transfer-stocks' },
      { title: 'Tạo phiếu chuyển kho' },
    ]);
    this.billNumber = this.$route.query.billId;
    if (!this.billNumber) {
      const { bills } = this.$route.params;
      if (bills && bills.length) {
        this.mapWarrantyBills(bills);
      }
    }
    const groupedProduct = this.groupProductsByQuantity(this.warrantyItems);
    this.warrantyItems = [...groupedProduct];
  },
  watch: {
    warrantyItems: function () {
      this.checkTotalMoney();
    },
  },
  methods: {
    bindClassNameStatus(status) {
      let className = '';
      switch (status) {
        case IMEI_STATUS.TRANSFERING: {
          className = 'label-light-warning';
          break;
        }
        case IMEI_STATUS.EXPORT_WARRANTY: {
          className = 'label-light-danger';
          break;
        }
      }
      return className;
    },
    onChangeTransferQuantity() {
      this.checkTotalMoney();
    },
    checkTotalMoney() {
      this.totalPrice = 0;
      this.totalQuantity = 0;
      this.warrantyItems.forEach((element) => {
        let total = element.quantity * unMaskPrice(element.productPrice);
        this.totalPrice += total;
        this.totalQuantity += parseInt(element.quantity);
      });
    },
    applyImei(imeiItem) {
      var updateIndex = this.warrantyItems.findIndex(
        (item) => item.id === this.selectedProduct.id,
      );
      const updateProduct = this.warrantyItems[updateIndex];
      updateProduct.imeiCode = imeiItem.imeiCode;
      updateProduct.quantity = 1;
      updateProduct.totalPrice =
        updateProduct.productPrice * updateProduct.quantity;

      this.hideModalListImei();
    },
    checkStatus: function (item) {
      switch (item) {
        case IMEI_STATUS.TRANSFERING:
          return 'Đang chuyển kho';
        case IMEI_STATUS.ON_WARRANTY:
          return 'Đang bảo hành';
        default:
          return '';
      }
    },
    hideModalListImei() {
      this.$refs['list-imei-modal'].hide();
    },
    getListImei(productId) {
      const param = {
        productId: productId,
        storeId: this.sourceStoreSelected,
        imeiStatus: IMEI_STATUS.ON_WARRANTY,
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('imeis/get-by-product', paramQuery).then(({ data }) => {
        this.listImeiByProduct = [];
        data.data.forEach((element) => {
          const item = {
            id: element.id,
            imeiCode: element.imeiNo,
            originalPrice: element.originalPrice ? element.originalPrice : 0,
            sellingPrice: element.sellingPrice ? element.sellingPrice : 0,
            status: element.status,
          };
          this.listImeiByProduct.push(item);
        });
      });
    },
    linkToListImei(product) {
      this.selectedProduct = { ...product };
      this.modalImeiTitle = `Danh sách IMEI sản phẩm: ${product.productName}`;
      this.getListImei(product.productId);
      this.$refs['list-imei-modal'].show();
    },
    onInputChangeSrcStore(text = '') {
      this.searchSourceStore = text;
      this.filteredOptions = this.options
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
    },
    searchProductAPI() {
      if (!this.isSearching) {
        let textSearch = this.searchProduct;
        this.fetchProduct(textSearch);
      }
    },
    debounceInputProduct: debounce(function () {
      this.searchProductAPI();
    }, TIME_TRIGGER),
    fetchProduct(textSearch) {
      this.isSearching = true;
      ApiService.get(
        `productSearch?storeId=${this.inventorySelected}&searchProduct=${textSearch}`,
      ).then(({ data }) => {
        this.optionsProduct = [];
        const products = data.data;
        products.map((element) => {
          this.optionsProduct.push(element);
        });
        this.filteredOptionsProduct = [...this.optionsProduct];
        this.isSearching = false;
        if (textSearch !== this.searchProduct) {
          const nextSearchProduct = this.searchProduct;
          this.fetchProduct(nextSearchProduct);
        }
      });
    },
    onSelectedProduct(option) {
      this.selectedProduct = { id: uuidv4(), ...option.item };
      if (this.selectedProduct.productType === PRODUCT_TYPE.PRODUCT_COMBO) {
        this.makeToastFaile('Không hỗ trợ nhập kho sản phẩm combo');
        return;
      }
      this.searchProduct = option.item.productName;
      this.count++;
      let quantity = 0;
      if (this.selectedProduct.productType === PRODUCT_TYPE.PRODUCT) {
        quantity = 1;
      }
      const productItem = {
        id: this.selectedProduct.id,
        productName: this.selectedProduct.productName,
        totalQuantityInStock:
          this.selectedProduct.quantityInStock !== null
            ? this.selectedProduct.quantityInStock
            : 0,
        imeiCode: '',
        productId: this.selectedProduct.productId,
        productPrice: this.selectedProduct.sellingPrice,
        quantity: quantity,
        discount: 0,
        totalPrice: quantity * this.selectedProduct.sellingPrice,
        productType: this.selectedProduct.productType,
        originalProductType: this.selectedProduct.productType,
        productCode: this.selectedProduct.productCode,
        barCode: this.selectedProduct.barCode,
      };
      this.warrantyItems.push(productItem);

      this.searchProduct = '';
    },
    onInputChangProduct(text = '') {
      this.searchProduct = text;
      this.debounceInputProduct();
    },
    groupProductsByQuantity(products) {
      const productGroup = {};

      for (const product of products) {
        if (product.originalProductType !== BILL_ITEM_TYPE.PRODUCT) {
          continue;
        }
        const key =
          product.productName + product.productType + product.productPrice;

        if (key in productGroup) {
          productGroup[key].quantity += product.quantity;
        } else {
          productGroup[key] = { ...product };
        }
      }

      return Object.values(productGroup);
    },
    mapWarrantyBills(bills) {
      const firstBill = bills[0];
      this.sourceStoreSelected = firstBill.storeId;
      this.searchSourceStore = firstBill.storeName;
      const productList = bills.flatMap((bill) => {
        return bill.listProduct
          .filter((product) => {
            return (
              !product.isLostProduct &&
              (bill.status !== WARRANTY_BILL_STATUS.DONE ||
                product.newProductId)
            );
          })
          .map((product) => {
            return {
              ...product,
              originalProductType: product.typeProduct,
              discountAmount: product.discount,
            };
          });
      });

      const mappedProducts = this.getWarrantyItems(productList);
      this.warrantyItems = this.warrantyItems.concat(mappedProducts);
    },
    deleteTransferItem(deleteItem) {
      const removeIdex = this.warrantyItems.findIndex(
        (obj) =>
          obj.id == deleteItem.id && obj.productId == deleteItem.productId,
      );
      this.warrantyItems.splice(removeIdex, 1);
    },
    convertPrice,
    createStock: async function (path) {
      if (this.searchSourceStore.trim() === '') {
        this.sourceStoreSelected = null;
      }
      if (this.searchDesStore.trim() === '') {
        this.desStoreSelected = null;
      }
      if (this.desStoreSelected === null) {
        return makeToastFaile('Vui lòng chọn kho đích!');
      }
      if (this.sourceStoreSelected === null) {
        return makeToastFaile('Vui lòng chọn kho nguồn!');
      }
      if (this.warrantyItems.length === 0) {
        return makeToastFaile('Vui lòng chọn sản phẩm cần chuyển kho!');
      }
      const listPro = this.warrantyItems.map((element) => {
        return {
          productId: element.productId,
          imeis: element.imeiCode.replace(/[\n\r]/g, ', '),
          quantity: Number(element.quantity),
          unitPrice: unMaskPrice(element.productPrice),
        };
      });

      const data = {
        sourceStoreId: this.sourceStoreSelected,
        desStoreId: this.desStoreSelected,
        description: this.notes,
        transferDate: this.transferDate
          ? moment(this.transferDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        listProduct: listPro,
        transferIntermediateItems: [],
        requireStockSlipType: STOCK_SLIP_TYPE.REQUIRE_EXPORT_WARRANTY_STOCK,
        billNumber: this.billNumber,
      };
      if (this.isNew === true) {
        this.isNew = false;
        ApiService.post(`stock-slips/${path}`, data)
          .then(({ data }) => {
            if (data.status === 1) {
              makeToastSuccess(data.message);
              this.$router.push({
                name: 'require-warranty-stocks',
              });
            } else {
              this.isNew = true;
              makeToastFaile(data.message);
            }
          })
          .catch((response) => {
            this.isNew = true;
            makeToastFaile(response);
          });
      }
    },
    showWarningAlert: function () {
      Swal.fire({
        title: 'Lưu và duyệt phiếu chuyển kho!',
        text: 'Bạn có chắc muốn lưu và duyệt phiếu chuyển kho này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Có',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.createStock('create-approve-stock-slip');
        }
      });
    },
    debounceTransfer: debounce(function (mode) {
      this.onTransfer(mode);
    }, TIME_TRIGGER),
    onTransfer(mode) {
      if (mode === 'save') {
        this.createStock('transfer-stock-slip');
      }

      if (mode === 'save-approve') {
        this.showWarningAlert();
      }
    },
    fetchStore: async function () {
      this.options = [];
      await ApiService.get(`stores?limit=10000&page=1`).then(({ data }) => {
        this.listInventories = data.data.list_store;
        this.listInventories.map((element) => {
          if (element.id === this.sourceStoreId) {
            this.searchSourceStore = element.name;
            this.sourceStoreSelected = element.id;
          }
          let store = {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
          this.options.push(store);
        });

        this.filteredOptions = [...this.options];
      });
    },
    fetchStoreByUser: async function () {
      await ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          if (stores.length === 1) {
            this.sourceStoreSelected = stores[0].id;
            this.searchSourceStore = stores[0].name;
            this.disableSrc = true;
          }
        }
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    onSelectedSrcStore(option) {
      this.searchSourceStore = option.item.name;
      this.sourceStoreSelected = option.item.id;
    },
    onSelectedDesStore(option) {
      this.searchDesStore = option.item.name;
      this.desStoreSelected = option.item.id;
    },
    onInputChangeDesStore(text) {
      this.searchDesStore = text;
      this.filteredOptions = this.options
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
    },
    getWarrantyItems(billItems) {
      const result = [];

      if (!billItems.length) return result;

      for (const billItem of billItems) {
        if (!billItem || billItem.isLostProduct) continue;

        if (
          billItem.productType === PRODUCT_TYPE.PRODUCT ||
          billItem.productType === PRODUCT_TYPE.PRODUCT_IMEI
        ) {
          const product = {
            id: billItem.id,
            productId: billItem.productId,
            productName: billItem.productName,
            productCode: billItem.productCode,
            productPrice: billItem.productPrice,
            productType: BILL_ITEM_TYPE.PRODUCT,
            originalProductType: billItem.productType,
            quantity: billItem.quantity,
            imeiCode: billItem.imeiCode ? billItem.imeiCode : '',
            totalPrice:
              billItem.quantity * billItem.productPrice -
              billItem.discountAmount,
          };
          result.push(product);
        }
        if (billItem.productType === PRODUCT_TYPE.PRODUCT_COMBO) {
          billItem.listProductInCombo.map((element) => {
            const product = {
              id: billItem.id,
              productId: element.productId,
              productName: element.productName,
              productCode: element.productCode,
              productPrice: element.sellingPrice,
              productType: BILL_ITEM_TYPE.PRODUCT,
              originalProductType: BILL_ITEM_TYPE.PRODUCT,
              quantity: element.quantity,
              imeiCode: element.imeiCode ? element.imeiCode : '',
              totalPrice:
                element.quantity * element.sellingPrice -
                (element.discountAmount || 0),
            };
            result.push(product);
          });
        }
      }

      return result;
    },
    fetchBillById: async function () {
      if (!this.billNumber) return;
      await ApiService.query(`bills/${this.billNumber}`).then((response) => {
        const { data } = response.data;
        if (!data) return;

        this.sourceStoreId = data.storeId;
        this.billStatus = data.status;

        const products = this.getWarrantyItems(data.billItems);
        this.warrantyItems = this.warrantyItems.concat(products);

        this.fetchStoreByUser();
      });
    },
  },
};
</script>

<style lang="css" scoped>
div /deep/ .modal-backdrop {
  opacity: 0.5;
}
</style>

<style lang="scss" scoped>
.suggestion-item {
  display: 'flex';
  color: 'navyblue';
}

.text-center {
  display: flex;
  align-items: center;
}

.transfer-warranty-stock {
  .title-center {
    text-align: center;
  }
}

.tdTextAlignPrice {
  text-align: right !important;
  vertical-align: middle;
  font-weight: 600;
}
</style>
